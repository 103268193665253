<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper/modules";
import axios from "axios";
import "swiper/css";
import "swiper/css/pagination";

const props = defineProps({
  categoryId: {
    type: Number,
    default: 4,
  },
});

const swiperOptions = {
  modules: [Pagination],
  pagination: false,
  breakpoints: {
    300: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 4,
    },
  },
  spaceBetween: 32,
};

const blogs = ref([]);
onMounted(async () => {
  try {
    const host = "https://mirawaygroup.com";
    const response = await axios.get(`${host}/blog/wp-json/wp/v2/posts`, {
      params: { categories: [props.categoryId], _fields: "id,title,content,excerpt,featured_image_url,link" },
    });
    blogs.value = response.data;
  } catch (error) {
    console.log(error);
  }
});

const formatDate = (date) => {
  const day = date.split("T")[0];
  const time = date.split("T")[1];

  return day.split("-").reverse().join("/") + " " + time;
};
</script>

<template>
  <section class="mt-[100px] max-md:mt-[80px] 2xl:container">
    <div class="text-center">
      <div class="text-[18px] text-center text-secondary max-md:text-[14px]">TÌM HIỂU NGAY</div>
      <h2 class="font-bold text-primary heading-2 mt-3 max-md:text-[28px]">Tin tức & sự kiện</h2>
    </div>

    <swiper class="mt-14 swiper-dot" v-if="blogs?.length" v-bind="swiperOptions">
      <swiper-slide v-for="(blog, index) in blogs" :key="index">
        <div class="h-full border border-solid border-[#E6E8EC] rounded-lg flex flex-col bg-white relative">
          <div
            class="absolute top-2 left-2 text-[#FCFCFD] py-2 px-3 text-[12px] rounded-xl tuyendung-bt-tintuc max-md:text-[12px] max-md:font-[700] max-md:leading-[19px]"
          >
            Tin tức
          </div>
          <a :href="blog.link" target="_blank">
            <nuxt-picture
              loading="lazy"
              :src="blog.featured_image_url"
              :img-attrs="{ class: 'w-full h-[196px] rounded-t-lg', alt: `blog-${index + 1}` }"
            />
          </a>

          <div class="p-4">
            <a :href="blog.link" target="_blank">
              <div
                class="font-semibold uppercase leading-[1.5] text-primary line-clamp-2"
                v-html="blog.title.rendered"
              />
            </a>

            <div v-html="blog?.excerpt.rendered" class="content-render text-large line-clamp-3 my-2" />
          </div>

          <div class="text-right mt-auto">
            <a :href="blog.link" target="_blank">
              <button class="text-primary border-transparent px-4">Xem thêm</button>
            </a>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </section>
</template>
